const getDefaultState = () => {
  return {
    // global
    activeTab: 0,

    // tab header
    tabHeader: {},

    // tab pekerjaan
    tabPekerjaan: {},

    // tab pembayaran
    tabPembayaran: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {},

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0 // safari
      document.documentElement.scrollTop = 0 // chrome, firefox, ie and opera
      state.activeTab = tabIndex
    },
    SET_TAB_HEADER (state, payload) {
      state.tabHeader = payload
    },
    SET_TAB_PEKERJAAN (state, payload) {
      state.tabPekerjaan = payload
    },
    SET_TAB_PEMBAYARAN (state, payload) {
      state.tabPembayaran = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
